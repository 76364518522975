import axios from 'axios';
import { mapToDataOnly } from '../base/HttpUtility';
import { StatusEnum } from 'src/components/order/Enum/StatusEnum';
import { PaginationView } from '../base/Pagination';
import { PaginationDto } from '../models/models';

let baseUrl = 'AgentOrderPackage'

export default class AgentOrderPackageService {
    insert(body: AgentOrderInsertDTO): Promise<string> {
        return mapToDataOnly<string>(
            axios.post(`/${baseUrl}/Insert`, body)
        );
    }

    get(orderNumber: string): Promise<OrderDto> {
        return mapToDataOnly<OrderDto>(
            axios.get(`/${baseUrl}/Get/${orderNumber}`)
        );
    }

    getPagedHistory(queryString: SaleAgentTransactionQueryString): Promise<PaginationView<AgentOrderHistoryDTO>> {
        return mapToDataOnly<PaginationView<AgentOrderHistoryDTO>>(
            axios.get(`/${baseUrl}/History`, { params: queryString })
        );
    }

    tracking(orderNumber: string): Promise<AgentTrackingDTO> {
        return mapToDataOnly<AgentTrackingDTO>(
            axios.get(`/${baseUrl}/Tracking/${orderNumber}`)
        );
    }

    tryAgain(orderNumber: string, callback: string): Promise<string> {
        return mapToDataOnly<string>(
            axios.get(`/${baseUrl}/TryAgain/${orderNumber}?callback=${callback}`)
        );
    }

    payment(orderNumber: string, trackId: string, gatewayKey: string): Promise<OrderDto> {
        return mapToDataOnly<OrderDto>(
            axios.put(`/${baseUrl}/Payment/${orderNumber}?trackId=${trackId}&gatewayKey=${gatewayKey}`)
        );
    }
}

export interface AgentOrderHistoryDTO {
}

export interface SaleAgentTransactionQueryString extends PaginationDto {
    packageId?: string;
    email?: string;
}

export interface AgentTrackingDTO {
    orderNumber: string;
    orderStatus: StatusEnum;
    inviteLink: string;
}

export interface AgentOrderInsertDTO {
    userId: string;
    packageId: string;
    callback: string;
}

export interface OrderDto {
    email: string | null;
    phoneNumber: string | null;
    title: string | null;
    enTitle: string;
    price: number | null;
    discount: number | null;
    dollarPrice: number | null;
    dollarDiscount: number | null;
    buyTime: string;
    duration: number | null;
    orderNumber: string | null;
    imageId: string | null;
    imageSrc: string | null;
    orderItemId: string | null;
    refId: string | null;
    orderStatus: StatusEnum | null;
}