import axios from 'axios';
import { returnAuthenticationUrl } from 'src/constants/constants';
import { mapToDataOnly } from './base/HttpUtility';

export default class UserService {
  getUsers(dto: UserParamsDto): Promise<PaginationDataDto> {
    return mapToDataOnly<PaginationDataDto>(
      axios.get(`${returnAuthenticationUrl()}/Admin/Users`, { params: dto })
    );
  }

  add(dto: AddUserDto): Promise<string> {
    return mapToDataOnly<string>(
      axios.post(`${returnAuthenticationUrl()}/Admin/Users`, { ...dto, email: dto.email ?? dto.userName })
    );
  }

  edit(userId: string, dto: AddUserDto): Promise<string> {
    return mapToDataOnly<string>(
      axios.patch(`${returnAuthenticationUrl()}/Admin/Users/${userId}`, { firstName: dto.firstName, lastName: dto.lastName, email: dto.email ?? dto.userName, phoneNumber: dto.phoneNumber, userName: dto.userName })
    );
  }

  delete(id: string): Promise<string> {
    return mapToDataOnly<string>(
      axios.delete(`${returnAuthenticationUrl()}/Admin/Users/${id}`,)
    );
  }

  assignRole(dto: { userId: string, roleId?: string, roleName: string }): Promise<string> {
    return mapToDataOnly<string>(
      axios.post(`${returnAuthenticationUrl()}/Role/AsignRole`, { ...dto, roleId: null })
    );
  }

  getUserCount(): Promise<number> {
    return mapToDataOnly<number>(
      axios.get(`${returnAuthenticationUrl()}/Admin/Users/GetUsersCount`)
    );
  }

  getUsersEmail(userIds: string[]): Promise<{ email: string, userId: string }[]> {
    return mapToDataOnly<{ email: string, userId: string }[]>(
      axios.post(`${returnAuthenticationUrl()}/User/ListUsersEmail`, userIds)
    );
  }

  getUsersStatistic(): Promise<UserStatisticView> {
    return mapToDataOnly<UserStatisticView>(
      axios.get(`${returnAuthenticationUrl()}/Admin/Users/Statistics?onlyInformationCompleted=true`)
    );
  }

  getUserInfo(): Promise<ProfileDataDto> {
    return mapToDataOnly<ProfileDataDto>(
      axios.get(`${returnAuthenticationUrl()}/User/GetUserInfo`)
    );
  }

  getUserIdByPhoneNumber(phoneNumber: string): Promise<string | null> {
    return mapToDataOnly<string | null>(
      axios.get(`${returnAuthenticationUrl()}/User/GetUserIdByPhoneNumber?phoneNumber=${phoneNumber}`)
    );
  }
}

export interface UserStatisticView {
  today: number,
  yesterday: number,
  currentWeek: number,
  lastWeek: number,
  currentMonth: number,
  lastMonth: number,
  total: number
}

export interface ProfileDataDto {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
}

export interface PaginationDataDto {
  rows: UserDto[];
  totalRows: number;
}
export interface UserDto {
  id: string;
  userName: string;
  email: string;
  phoneNumber: string;
  createDate: string;
  firstName?: string;
  lastName?: string;
}

export interface AddUserDto {
  userName?: string;
  password?: string;
  phoneNumber?: string;
  id?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
}

export interface UserParamsDto {
  userRoles?: string;
  UserId?: string;
  pageSize: number;
  pageSkip: number;
  FullName?: string;
  phoneNumber?: string;
  email?: string;
}
